import {
    ref,
    reactive
} from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useFinancialAccounts() {

    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]); //ref: to make it reactive
    const financial_accounts = ref([]); //ref: to make it reactive
    const financial_account = ref([]); //ref: to make it reactive
    const lists = reactive({});

    const getFinancialAccounts = async (data = {}) => {
        try {
            let response = await axios.post('/api/financial_accounts/get', data);
            financial_account.value = response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getFinancialAccount = async (id) => {
        try {
            let response = await axios.get('/api/financial_accounts/' + id);
            financial_account.value = response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const storeFinancialAccount = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/financial_accounts/', data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    const getListsForFinancialAccounts = async () => {
        try {
            let response = await axios.post('/api/financial_accounts/lists');
            lists.types = response.data.data.types;
            lists.currencies = response.data.data.currencies;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const deleteFinancialAccount = async(id) => {
        try {
            await axios.delete('/api/financial_accounts/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    } 
    
    const updateFinancialAccount = async(data, id) => {
        errors.value = ''; //
        try {
            data.append("_method", 'PATCH');
            let response = await axios.post('/api/financial_accounts/' + id, data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    return {
        financial_accounts,
        financial_account,
        storeFinancialAccount,
        getFinancialAccounts,
        getFinancialAccount,
        deleteFinancialAccount,
        getListsForFinancialAccounts, 
        updateFinancialAccount,
        lists,
        msgs,
        errors,
    }
}