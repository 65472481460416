<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Financial Accounts</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Financials' }"
              >Financial</router-link
            >
          </li> 
          <li class="breadcrumb-item active">Accounts</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <router-link
              v-can="'add_financial_accounts'"
              :to="{ name: 'Financial-Accounts-Create' }"
              class="btn btn-primary"
              >Add New</router-link
            >
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <!-- form will be here -->
              </div>
            </div>
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-striped
                  verticle-middle
                  table-responsive-sm
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">ID Number</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Branch Name</th>
                    <th scope="col">phone</th>
                    <th scope="col">email</th>
                    <th scope="col">address</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody v-if="financial_account.length">
                  <tr v-for="account in financial_account" :key="account.id">
                    <td>{{ account.name }}</td>
                    <td>{{ account.type }}</td>
                    <td>{{ account.id_number }}</td>
                    <td>
                      {{ account.currency.name }} [{{
                        account.currency.abbreviation
                      }}]
                    </td>
                    <td>{{ account.branch_name }}</td>
                    <td>{{ account.phone }}</td>
                    <td>{{ account.email }}</td>
                    <td>{{ account.address }}</td>
                    <td>
                      <span>
                        <router-link
                          :to="{
                            name: 'Financial-Accounts-Single',
                            params: { id: account.id },
                          }"
                          title="View"
                          class="mr-4"
                          ><i class="fa fa-eye color-muted"></i>
                        </router-link>
                        <router-link
                          v-can="'edit_financial_accounts'"
                          :to="{
                            name: 'Financial-Accounts-Edit',
                            params: { id: account.id },
                          }"
                          title="Edit"
                          class="mr-4"
                          ><i class="fa fa-pencil color-muted"></i>
                        </router-link>
                        <a
                          v-can="'delete_financial_accounts'"
                          @click.prevent="deleteAccount(account.id)"
                          href="javascript:void()"
                          title="Delete"
                          ><i class="fa fa-close color-danger"></i
                        ></a>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="text-align: center" colspan="9">
                      No Data Available.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useFinancialAccounts from "@/composables/financial_accounts";
import { onMounted, inject, onBeforeMount, reactive } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    const search_fields = reactive({
      name: "",
      type: "",
      id_number: "",
      email: "",
      phone: "",
    });

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_financial_accounts"))
        router.push({
          name: "401",
        });
    });

    const { financial_account, getFinancialAccounts, deleteFinancialAccount } =
      useFinancialAccounts();

    onMounted(() => {
      getFinancialAccounts();
    });

    const deleteAccount = async (id) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteFinancialAccount(id).then(() => {
              swal.fire("Deleted!", "The data has been deleted successfully.", "success");
              getFinancialAccounts();
            });
          }
        });
    };

    const submitSearchForm = async () => {
      await getFinancialAccounts(search_fields);
    };

    return {
      financial_account,
      deleteAccount,
      submitSearchForm,
    };
  },
};
</script>